import {
  WAG_SAFETY_BASE_URL,
  WagWebAppUrl,
  BASE_URL,
  WAG_CONTENT_BASE_URL,
} from './app';

// URL / Route constants
export const Routes = {
  VetForm: '/ask-an-expert/vet-form',
  OrganicVetForm: '/ask-an-expert/vet-form/organic',
  VetFormSuccess: '/ask-an-expert/vet-form-success',
  OrganicVetFormSuccess: '/ask-an-expert/vet-form/organic-success',
  BehavioristOrganicFormSuccess: '/ask-an-expert/behavorist-form/organic-success',
  DigitalTraining: '/digital-training',
  GiftCardTerms: `${WAG_SAFETY_BASE_URL}/terms-gift-card`,

  // TODO - Use wagcontent env var for domain rather than hardcoding domain
  ConditionArticleDirectory: 'https://wagwalking.com/care/conditions',
  SymptomArticleDirectory: 'https://wagwalking.com/care/symptoms',
  TreatmentArticleDirectory: 'https://wagwalking.com/care/treatments',

  CatConditionArticleDirectory: 'https://wagwalking.com/cat/conditions',
  CatSymptomArticleDirectory: 'https://wagwalking.com/cat/symptoms',
  CatTreatmentArticleDirectory: 'https://wagwalking.com/cat/treatments',

  HorseConditionArticleDirectory: 'https://wagwalking.com/horse/conditions',
  HorseTreatmentArticleDirectory: 'https://wagwalking.com/horse/treatments',

  ReptileConditionArticleDirectory: 'https://wagwalking.com/reptile/conditions',
  BirdConditionArticleDirectory: 'https://wagwalking.com/bird/conditions',
  RabbitConditionArticleDirectory: 'https://wagwalking.com/rabbit/conditions',
  ChinchillaConditionArticleDirectory: 'https://wagwalking.com/chinchilla/conditions',
  FerretConditionArticleDirectory: 'https://wagwalking.com/ferret/conditions',

  WalkerTaxPreferencesPageCanonical: 'https://wagwalking.com/walker/tax-preferences',

  DogBoardingArticleDirectory: `${BASE_URL}/dog-boarding`,
  DogTrainingArticleDirectory: `${BASE_URL}/dog-training`,
  DogWalkingArticleDirectory: `${BASE_URL}/dog-walking`,
  DogGroomingArticleDirectory: `${BASE_URL}/pet-grooming`,
  CatBoardingArticleDirectory: `${BASE_URL}/cat-sitting`,
  DogDayCareArticleDirectory: `${BASE_URL}/dog-daycare`,
  PartnersPage: `${BASE_URL}/partners/:slug`,
  PartnersPageSuccess: `${BASE_URL}/partners/:slug/success`,

  OrganicVetFormUtm: 'https://wagwalking.com/ask-an-expert/vet-form/organic?utm_content=widget_vet&utm_medium=partner_widget',
  DogOwnerUtm: 'https://wagwalking.com/dog-walking?utm_content=widget_owner&utm_medium=partner_widget',
  DogWalkerUtm: 'https://wagwalking.com/dog-walker?utm_content=widget_dog_walker&utm_medium=partner_widget',
  DogTrainingUtm: 'https://training.wagwalking.com/dog-training/?utm_content=widget_trainings&utm_medium=partner_widget',

  WalkerProfileDirectory: 'https://wagwalking.com/walker-profile',
  DogTrainingFindMoreTrainers: 'https://wagwalking.app.link/5IBvicfrU8',
  DogTrainingBookNow: 'https://wagwalking.app.link/JVnMgvorU8',
  DogSittingFindMoreSitters: WagWebAppUrl.SignupFlow,
  DogBoardingFindMoreBoarders: WagWebAppUrl.SignupFlow,

  App: '/app',
  BookingFlow: {
    AddPetInfo: '/booking-flow/add-pet-info',
    SelectService: '/booking-flow/service-type',
  },
  CatSitting: '/cat-sitting',
  DogBoarding: '/dog-boarding',
  DogDaycare: '/dog-daycare',
  DogSitting: '/dog-sitting',
  DogWalking: '/dog-walking',
  DogTraining: '/dog-training',
  DogSitter: '/dog-sitter',
  DogWalker: '/dog-walker',
  DropIn: '/drop-ins',
  DogeGiftCard: '/doge-gift-card',
  GiftCard: '/gift-card',
  Health: '/wag-health',
  OrganicBehavioristForm: '/ask-an-expert/behavorist-form/organic',
  Partners: '/partners',
  PuppyTraining: '/puppy-training',
  StateOfIndustry: '/state-of-the-pet-industry',
  Wellness: '/wag-wellness',
  WellnessMember: '/wellness-member',
  WagPremium: '/wag-premium',
  WagWellnessPetInsurance: '/wag-wellness/pet-insurance',
  WagWellnessPlans: '/wag-wellness/wellness-plans',
  StripeConnectWalkerVerify: '/stripe-connect/walker/verify',

  WagPremiumMobileDeepLink: 'dlwag://wagpremium',

  CovidDailyArticle: 'https://wagwalking.com/daily/coronavirus-and-your-pet-resource-center',
  PsychologicalBenefitsOfSheltingDailyArticle: 'https://wagwalking.com/daily/the-psychological-benefits-of-sheltering-in-place-with-your-dog',

  WellnessSuccessSignup: 'https://wagwalking.com/daily/youve-joined-the-waitlist',
  DigitalTrainingFindMyTrainerLink: 'https://wagwalking.app.link/ALO76ikPk8',
  DogTrainingFindMyTrainerLink: 'https://wagwalking.app.link/rA5vaYePk8',

  QrCode: 'https://assets.wagwalking.com/branch/qr-wag-app.png',
  PetInsuranceQuoteLink: 'https://quote.petinsurer.com/quote/ref=wag',
  PetInsurer: 'https://PetInsurer.com',
  Petted: 'https://petted.com',

  DogWalkerFaq: 'https://help.wagwalking.com/t/y4hm93h',
  PricingHelp: 'https://help.wagwalking.com/t/h7htjlx',
  WagHealthHelp: 'https://help.wagwalking.com/t/q6hxrfj/wag-health',
  MobileTerms: 'https://safety.wagwalking.com/terms-pet-caregiver-mobile',
  TermsAndConditions: 'https://safety.wagwalking.com/terms',
  TermsAndConditionsApp: 'https://safety.wagwalking.com/terms/app',
  TermsAndConditionsArbitration: 'https://safety.wagwalking.com/terms#arbitrataion',
  MutualArbitrationClause: 'https://safety.wagwalking.com/terms',
  PrivacyPolicy: 'https://safety.wagwalking.com/privacy',
  CustomerClaimsPolicy: 'https://safety.wagwalking.com/customer-claims-policy',

  DogBoardingBusiness: 'https://wagwalking.com/daily/how-to-start-a-dog-boarding-business',
  PetSittersChecklist: 'https://wagwalking.com/daily/pet-sitters-meet-greet-checklist-for-new-pet-sitters',

  FunNameGuides: 'https://wagwalking.com/daily/names',
  FunDogActivitiesGuides: 'https://wagwalking.com/activities',
  FunBlog: 'https://wagwalking.com/daily',

  KnowledgeCareGuides: 'https://wagwalking.com/care',
  TrainingGuides: `${WAG_CONTENT_BASE_URL}/training`,
  PetTrainingGuides: `${WAG_CONTENT_BASE_URL}/{{animalTypeSlug}}/training`,
  KnowledgeBreedGuides: 'https://wagwalking.com/breed',
  KnowledgeSenseGuides: 'https://wagwalking.com/daily/senses',
  KnowledgeBehaviorGuides: 'https://wagwalking.com/daily/behavior',
  KnowlegeGroomingGuides: 'https://wagwalking.com/grooming',
  KnowledgeWellnessGuides: 'https://wagwalking.com/wellness',

  LifestyleParksGuides: 'https://wagwalking.com/lifestyle/parks',
  LifestyleBeachesGuides: 'https://wagwalking.com/lifestyle/beaches',
  LifestyleCampgroundsGuides: 'https://wagwalking.com/lifestyle/campgrounds',
  LifestyleTrailsGuides: 'https://wagwalking.com/lifestyle/trails',
  LifestyleDogOwnership: 'https://wagwalking.com/lifestyle/dog-ownership',
  LifestyleHolidayGuides: 'https://wagwalking.com/lifestyle/holiday',
  LifestyleProductGuides: 'https://wagwalking.com/lifestyle/products',

  DogVideos: 'https://wagwalking.com/trust-safety/videos',

  WagServicesDogWalking: 'https://wagwalking.com/dog-walking',
  WagServicesDogSitting: 'https://wagwalking.com/dog-sitting',
  WagServicesDogBoarding: 'https://wagwalking.com/dog-boarding',
  WagServicesWagHealth: 'https://wagwalking.com/wag-health',
  WagServicesDogTraining: 'https://wagwalking.com/dog-training',
  WagServicesDigitalTraining: 'https://wagwalking.com/digital-training',
  WagServicesDropIns: 'https://wagwalking.com/drop-ins',
  WagServicesWagWellness: 'https://wagwalking.com/wag-wellness',

  Bitpay: 'https://bitpay.com',

  Deals: 'https://wagwalking.com/deals',

  Waggle: process.env.NEXT_PUBLIC_WAGGLE_URL || 'https://assets.wagwalkingweb.com/waggle/index.html',

  PawProtect: 'https://pawprotect.com/',
};

// TODO: Routes enum above doesn't work for specifying routes with a dynamic parameter.
// We should probably consider removing Routes enum and just turning those into
// constants to keep consistent with this.
export const URL_STATE_DIRECTORY = ({ state }) => `/${state}`; // Ex: /ca
export const URL_CITY_DIRECTORY = ({ state, city }) => `/${state}/${city}`; // Ex: /ca/san-francisco
export const URL_CITY_TRAINING_ARTICLE = ({ locationSlug }: { locationSlug: string }) => `/dog-training/${locationSlug}`;

export const URL_MAPBOX_PLACES_SEARCH = ({ query }) => `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json`;

export const SitemapUrls = [
  Routes.App,
  Routes.CatSitting,
  Routes.DogWalking,
  Routes.DogBoarding,
  Routes.DogDaycare,
  Routes.DogSitting,
  Routes.DogTraining,
  Routes.DogSitter,
  Routes.DigitalTraining,
  Routes.DropIn,
  Routes.DogeGiftCard,
  Routes.GiftCard,
  Routes.Health,
  Routes.Partners,
  Routes.OrganicBehavioristForm,
  Routes.PuppyTraining,
  Routes.StateOfIndustry,
  Routes.Wellness,
  Routes.WagPremium,
  Routes.WagWellnessPetInsurance,
  Routes.WagWellnessPlans,
  Routes.StripeConnectWalkerVerify,
];
